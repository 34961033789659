<template>
  <content-not-view v-if="!subPermission.show" />
  <loading v-else-if="isLoading" />
  <b-card v-else class="parents-requests-list">
    <overlay-component :isLoading="isBusy" />
    <b-row class="d-flex justify-content-between p-1 align-items-end">
      <h4>
        {{ $t("parentRequests.head") }}
      </h4>
    </b-row>
    <b-row class="d-flex justify-content-between p-1">
      <b-form-input
        v-model="searchTerm"
        class="col-9"
        :placeholder="$t('g.searchHere')"
      />
      <b-form-select :options="filterOptions" v-model="filter" class="col-2" />
    </b-row>
    <vue-good-table
      :columns="tableHead"
      :rows="tableBody"
      styleClass="vgt-table  striped "
      :search-options="{
        searchFn: searchFunction,
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'actions'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              v-if="subPermission.update || subPermission.destroy"
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                v-if="subPermission.update"
                @click="handelRequest(props.row.id, 1)"
              >
                <feather-icon icon="CheckCircleIcon" class="mr-50" />
                <span>{{ $t("parentRequests.approve") }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="subPermission.destroy"
                @click="handelRequest(props.row.id, 0)"
              >
                <feather-icon icon="XCircleIcon" class="mr-50" />
                <span>{{ $t("parentRequests.reject") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span
          v-else-if="props.column.field === 'image'"
          class="d-flex justify-content-center"
        >
          <b-avatar :src="props.row.image" size="3rem" />
        </span>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t("g.pagelength") }}
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5', '10', '20']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap">
              {{ $t("g.of") }} {{ props.total }}
              {{ $t("g.pageText") }}
            </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
      <template slot="emptystate">
          <span class="text-center" style="width: 100%; display: inline-block;">
            {{ $t("g.no_data_for_table") }}
          </span>
        </template>
    </vue-good-table>
  </b-card>
</template>
<script>
import overlayComponent from "@/components/shared/OverlayComponent/index.vue";
import {
  BFormSelect,
  BPagination,
  BCard,
  BDropdownItem,
  BDropdown,
  BFormCheckbox,
  BButton,
  BFormInput,
  BRow,
  BFormDatepicker,
  BAvatar,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import loading from "@/components/loading/loading.vue";

export default {
  name: "ParentsRequestList",
  components: {
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormSelect,
    VueGoodTable,
    BCard,
    BButton,
    BFormInput,
    BRow,
    BFormDatepicker,
    loading,
    overlayComponent,
    BAvatar,
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "guardians");
      return this.$store.state.userData.sub_Permission;
    },
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      searchTerm: "",
      tableHead: [
        {
          label: "#",
          field: "i",
        },
        {
          label: this.$t("parentRequests.parentImage"),
          field: "image",
          sortable: false,
        },
        {
          label: this.$t("parentRequests.name"),
          field: "parentName",
          sortable: false,
        },
        {
          label: this.$t("parentRequests.email"),
          field: "email",
          sortable: false,
        },
        {
          label: this.$t("parentRequests.studentName"),
          field: "childName",
          sortable: false,
        },
        {
          label: this.$t("parentRequests.actions"),
          field: "actions",
          sortable: false,
        },
      ],
      tableBody: [],
      pageLength: 20,
      filterOptions: [
        {
          value: null,
          text: this.$t("parentRequests.search.default"),
          disabled: true,
        },
        {
          value: "childName",
          text: this.$t("parentRequests.search.studentName"),
        },
        {
          value: "email",
          text: this.$t("parentRequests.search.email"),
        },
        {
          value: "parentName",
          text: this.$t("parentRequests.search.name"),
        },
      ],
      filter: "childName",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const response = await this.$http.get("admin/child-requests");
        if (response.status === 200 || response.status === 201) {
          response.data.data.forEach(({ id, student, guardian }, i) => {
            this.tableBody.push({
              i: i + 1,
              id,
              email: guardian.email,
              childName: `${student.first_name} ${student.last_name}`,
              parentName: `${guardian.first_name} ${guardian.last_name}`,
              image: this.getParentImage(
                guardian.media,
                guardian.first_name,
                guardian.last_name
              ),
            });
          });
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handelRequest(_id, newStatus) {
      this.isBusy = true;
      try {
        const formData = new FormData();
        formData.append("is_approved", newStatus);
        const request = await this.$http.post(
          `admin/update-child-requests/${_id}`,
          formData
        );
        if ([200, 201].includes(request.status)) {
          this.$helpers.makeToast(
            "success",
            newStatus === 1
              ? this.$t("parentRequests.alerts.approved")
              : this.$t("parentRequests.alerts.reject")
          );
          this.tableBody = this.tableBody.filter((ele) => ele.id !== _id);
          this.tableBody.forEach((ele, i) => {
            ele.i = i + 1;
          });
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isBusy = false;
      }
    },
    getParentImage(mediaObj, fName, lName) {
      if (Array.isArray(mediaObj) && mediaObj.length > 0) {
        return mediaObj[0].path;
      } else {
        return this.$helpers.uiAvatar(`${fName} ${lName}`);
      }
    },
    searchFunction(row, col, cellValue, searchTerm) {
      if (this.filter) {
        if (row[this.filter].includes(searchTerm)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.options {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}
h4 {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
</style>
